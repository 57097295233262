import React, { useState } from 'react';
import './mobile-menu.scss';

import logo from "../../assets/img/logo-white.svg";
import close from "../../assets/img/close-outline.svg";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { checkSessionStorageToken } from '../../helpers/functions';
import ModalShareCodeUnitsMobileNav from '../ModalShareCodeUnitsMobileNav';

function MenuMobile({setShow}:{showProp: boolean, setShow: any}) {

  const authed = checkSessionStorageToken();
  const [hideMenu, setHideMenu] = useState(false);

  const handleClick = () => {
    setHideMenu(true); 
    setTimeout(() => setShow(false), 500); 
  };

  return (
    <div className={`mobile-menu ${hideMenu ? 'hide' : ''}`}>
      <nav className="navbar-menu">
        <div className="mobile-logo">
          <img src={logo} alt="lokl-logo" />
        </div>

        <div onClick={() => setShow(false)}>
          <img src={close} alt="close-menu" className="close-menu" />
        </div>
      </nav>

      <div className="desc-menu  mx-[50px]">
        <ul className="menu-items big-titles">
          <li className="title">
            <Link to="/dashboard" onClick={handleClick}>Mis inversiones</Link>
          </li>
          <li className="title">
            <HashLink to="/#simulator-home-form" onClick={handleClick}>Simulador</HashLink>
          </li>
          <li className="title">
            <HashLink to="/#projectCardID" onClick={handleClick}>Proyectos</HashLink>
          </li>
          <li className="title">
            <a
              id="btnContactanos"
              href="https://api.whatsapp.com/send/?phone=573207379535"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClick}
            >
              Contáctanos
            </a>
          </li>
          <li className="title">
            <Link to="/aboutus" onClick={handleClick}>Nosotros</Link>
          </li>
          <li className="title">
            <Link to="/blog" onClick={handleClick}>Aprende</Link>
          </li>
        </ul>

        
        {!authed ? (
          <div className="auth-section">
            <ul className="menu-items-bot titles">
              <li className="title underline">
                <a href="/register" onClick={handleClick}>Únete a LOKL</a>
              </li>
            </ul>
            <div className="info">
              Sé parte de una comunidad, crece tu patrimonio y construye tu futuro con LOKL
            </div>
          </div>
        ) : (
          <div className="auth-section">
           
            <ModalShareCodeUnitsMobileNav />
          </div>
        )}
      </div>
    </div>
  );
}

export default MenuMobile;
