import React, { useEffect, useState } from 'react';
import './navbar.scss';

import menu from "../../assets/img/menu.svg";
import menuWhite from "../../assets/img/menu-white.svg";
import logo from "../../assets/img/header/logo.png";
import logoWhite from "../../assets/img/logo-white.svg";
import avatar from "../../assets/img/topbar/avatar.svg";
import avatarAuthed from "../../assets/img/topbar/avatar-authed.svg";
// import nidoIcon from "../../assets/img/topbar/nido-icon.png";
// import fireBlack from "../../assets/img/topbar/fire-black.svg";

import MenuMobile from './menuMobile';
import jwt_decode from "jwt-decode";
import { on, trigger } from '../../helpers/events';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { authUrls, checkSessionStorageToken, isAuthenticated } from '../../helpers/functions';
import { motion } from 'framer-motion';
import { getUserData } from '../../services/ownerServices';
import { useCountdown } from '../../hooks/useCountdown';
import { HashLink } from 'react-router-hash-link';

const routesToHideTopBarBanner = ['/login', '/register', '/confirmation-code', '/home', '/', '/home/', '/aboutus' , ]

function Topbar() {

  const location = useLocation()

  const dateText = "Aug 31, 2024 23:59:59";

  let [show, setShow] = useState(false);

  let [authed, setAuthed] = useState(false);

  const [name, setName] = useState('');

  const { countdown } = useCountdown();


  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const inDashboard = url.pathname.includes("dashboard");

  let [currentTime, setCurrenTime] = useState('');
  const objectiveTime = new Date(dateText);
  setInterval(() => {
    const now = new Date(),
      time = (objectiveTime.getTime() - now.getTime() + 1000) / 1000,
      seconds = ('0' + Math.floor(time % 60)).slice(-2),
      minutes = ('0' + Math.floor(time / 60 % 60)).slice(-2),
      hours = ('0' + Math.floor(time / 3600 % 24)).slice(-2),
      days = Math.floor(time / (3600 * 24)),
      sDays = days ? `${days} días` : '';
    setCurrenTime(`${sDays} ${hours}:${minutes}:${seconds}`);
  }, 1000);

  const goToRegister = () => {

      let urlToRedirect = '';

      if (authUrls.includes(window.location.pathname)) {
          const url = new URL(window.location.href);
          urlToRedirect = `/register${url.search}`;
      } else {
          urlToRedirect = `/register?redirect_to=${window.location.pathname + window.location.hash}`;
      }

      navigate(urlToRedirect);
  };


  const goToLogin = () => {
    let urlToRedirect = '';

    if (authUrls.includes(window.location.pathname)) {
        const url = new URL(window.location.href);
        urlToRedirect = `/login${url.search}`;
    } else {
        urlToRedirect = `/login?redirect_to=${window.location.pathname + window.location.hash}`;
    }

    navigate(urlToRedirect);
};

  const closeSession = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('user_id');
    trigger('unauthed');
    navigate('/');
    setAuthed(false);
  }

  // funcion para activar o desactivar el contentido
  const activate = (token: any) => {

    if (token !== null) {

      const decoded: any = jwt_decode(token);
      const dateNow = new Date();

      if (decoded.exp * 1000 < dateNow.getTime()) {
        setAuthed(false);
      } else {
        setAuthed(true);
      }

    } else {
      setAuthed(false);
    }

  }

  const getData = async (id: string) => {
    const { data } = await getUserData();
    const userName = data.data.firstName + ' ' + data.data.lastName;
    setName(userName);
  }

  const redirecToBenefits = () => {
    window.location.href = "/nido#benefits"
    // if (authed === true){
    // }else{
    //   window.location.href = "/register?redirect_to=/nido#benefits"
    // }
  }

  useEffect(() => {
    const token = localStorage.getItem("token");

    activate(token);

    on('authed', () => {
      setAuthed(true);
    });

    on('unauthed', () => {
      setAuthed(false);
    });

  }, []);

  useEffect(() => {

    const token = localStorage.getItem("token") ?? '';

    if (checkSessionStorageToken() && inDashboard) {
      const decoded: any = jwt_decode(token);
      getData(decoded.id);
    }

  }, [inDashboard]);

  return (
    <>
      {show ? <MenuMobile showProp={show} setShow={setShow} /> : null}

      <nav className="navbar bg-white z-[60] shadow-sm">

        <div className="navbar-side navbar-left">
          <div className="navbar-menu mobile">
            <button onClick={() => navigate('/')}>
              <img src={menu} alt="lokl-menu" />
            </button>
          </div>
          <div className="navbar-logo">
            <button onClick={() => navigate('/')}>
              <img src={logo} alt="lokl-logo" />
            </button>
          </div>
          <div className="navbar-items gap-4">


            <div className="flex items-center gap-2">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 9.15293C1.5 7.43662 1.5 6.57846 1.8894 5.86706C2.2788 5.15566 2.99021 4.71413 4.41302 3.8311L5.91302 2.90015C7.41704 1.96672 8.16907 1.5 9 1.5C9.83093 1.5 10.5829 1.96672 12.087 2.90015L13.587 3.83109C15.0098 4.71413 15.7212 5.15566 16.1106 5.86706C16.5 6.57846 16.5 7.43662 16.5 9.15293V10.2938C16.5 13.2194 16.5 14.6822 15.6213 15.5911C14.7427 16.5 13.3284 16.5 10.5 16.5H7.5C4.67157 16.5 3.25736 16.5 2.37868 15.5911C1.5 14.6822 1.5 13.2194 1.5 10.2938V9.15293Z" stroke="#1C274C" strokeWidth="1.5" />
                <path d="M6.75 12C7.38779 12.4727 8.16345 12.75 9 12.75C9.83655 12.75 10.6122 12.4727 11.25 12" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
              <HashLink to="/#newprojects" className="no-underline font-semibold text-lg font-epilogue">Proyectos</HashLink>
            </div>

            <div className="flex items-center gap-2">
              <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.1024 7.20199C15.1024 3.22412 11.7151 0 7.55142 0C3.38755 0.000219432 0 3.24255 0 7.22043C0 8.67746 0.446498 10.0695 1.29494 11.2641L0.0679853 13.5715C-0.0305474 13.7564 -0.0213603 13.9537 0.0923314 14.1306C0.206023 14.3077 0.407682 14.3919 0.625189 14.3919H7.55142C11.7151 14.3919 15.1024 11.1801 15.1024 7.20199ZM2.52281 10.8542C1.69022 9.80271 1.25015 8.54821 1.25015 7.22657C1.25015 3.90721 4.07682 1.20644 7.55119 1.20644C11.0256 1.20644 13.8522 3.82142 13.8522 7.14077C13.8522 10.4603 11.0258 13.0753 7.55119 13.0753H1.64382L2.58114 11.3995C2.68978 11.1959 2.66704 11.0364 2.52281 10.8542ZM19.9321 18.252C20.0306 18.4372 20.0214 18.5978 19.9077 18.7746C19.7941 18.9517 19.5924 19 19.3749 19H12.4489C9.96168 19 7.63433 17.8892 6.22295 15.9282C6.02726 15.6567 6.09938 15.3149 6.38373 15.1279C6.6683 14.9409 7.05738 15.0136 7.25284 15.2852C8.43087 16.922 10.3733 17.9028 12.4489 17.9028H18.356L17.4187 16.1526C17.3103 15.949 17.333 15.7098 17.4773 15.5275C18.3096 14.476 18.7499 13.2243 18.7499 11.9025C18.7499 9.90233 17.7134 8.03913 15.9772 6.91563C15.6913 6.73087 15.6166 6.36025 15.8103 6.08706C16.0036 5.81408 16.3923 5.74277 16.6782 5.92775C18.7582 7.27353 19.9998 9.50801 19.9998 11.9049C19.9998 13.3621 19.5534 14.7511 18.7049 15.9457L19.9321 18.252ZM6.92623 4.18831C6.92623 3.8585 7.20598 3.63973 7.55119 3.63973H10.67C11.015 3.63973 11.295 3.8585 11.295 4.18831C11.295 4.51812 11.015 4.73689 10.67 4.73689H7.55119C7.20598 4.73689 6.92623 4.51812 6.92623 4.18831ZM3.80764 7.04093C3.80764 6.71112 4.08739 6.49235 4.43259 6.49235H10.67C11.015 6.49235 11.295 6.71112 11.295 7.04093C11.295 7.37074 11.015 7.58951 10.67 7.58951H4.43259C4.08739 7.58951 3.80764 7.37074 3.80764 7.04093ZM3.80764 9.89355C3.80764 9.56375 4.08739 9.34497 4.43259 9.34497H10.67C11.015 9.34497 11.295 9.56375 11.295 9.89355C11.295 10.2231 11.015 10.4421 10.67 10.4421H4.43259C4.08739 10.4421 3.80764 10.2231 3.80764 9.89355Z" fill="#5452F6" />
              </svg>

              <a id='btnContactanos' href="https://api.whatsapp.com/send/?phone=573207379535" className="no-underline font-semibold text-lg font-epilogue text-[#5452F6]" target='_blank' rel="noreferrer">Contáctanos</a>
            </div>

            <div className="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><circle cx="4" cy="14" r="2" fill="currentColor"/>
            <path fill="currentColor" d="M1.22 17.58A2.01 2.01 0 0 0 0 19.43V21h4.5v-1.61c0-.83.23-1.61.63-2.29c-.37-.06-.74-.1-1.13-.1c-.99 0-1.93.21-2.78.58"/><circle cx="20" cy="14" r="2" fill="currentColor"/><path fill="currentColor" d="M22.78 17.58A6.95 6.95 0 0 0 20 17c-.39 0-.76.04-1.13.1c.4.68.63 1.46.63 2.29V21H24v-1.57c0-.81-.48-1.53-1.22-1.85m-6.54-.93c-1.17-.52-2.61-.9-4.24-.9s-3.07.39-4.24.9A2.99 2.99 0 0 0 6 19.39V21h12v-1.61c0-1.18-.68-2.26-1.76-2.74M9 12c0 1.66 1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3s-3 1.34-3 3"/>
            <path fill="currentColor" d="M2.48 10.86C2.17 10.1 2 9.36 2 8.6C2 6.02 4.02 4 6.6 4c2.68 0 3.82 1.74 5.4 3.59C13.57 5.76 14.7 4 17.4 4C19.98 4 22 6.02 22 8.6c0 .76-.17 1.5-.48 2.26c.65.31 1.18.82 1.53 1.44c.6-1.2.95-2.42.95-3.7C24 4.9 21.1 2 17.4 2c-2.09 0-4.09.97-5.4 2.51C10.69 2.97 8.69 2 6.6 2C2.9 2 0 4.9 0 8.6c0 1.28.35 2.5.96 3.7c.35-.62.88-1.13 1.52-1.44"/>
            </svg>

              <Link to="/aboutus" id='btnAboutUs' className="no-underline font-semibold text-lg font-epilogue text-nowrap">Nosotros</Link>
            </div>

            

            {/* <div className="flex items-center gap-2">
              <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3384 4.70947C7.48741 4.70947 5.16797 7.0354 5.16797 9.89466C5.16797 12.7531 7.48729 15.0798 10.3384 15.0798C13.1896 15.0798 15.5089 12.7539 15.5089 9.89466C15.5089 7.03623 13.1896 4.70947 10.3384 4.70947ZM10.3384 13.5984C8.30255 13.5984 6.64524 11.9363 6.64524 9.89466C6.64524 7.85299 8.30255 6.19095 10.3384 6.19095C12.3743 6.19095 14.0316 7.85299 14.0316 9.89466C14.0316 11.9373 12.3743 13.5984 10.3384 13.5984Z" fill="black" />
                <path d="M11.3002 8.32676L9.81461 9.77492L9.4776 9.26102C9.25324 8.92028 8.79529 8.82305 8.45459 9.04806C8.11298 9.27213 8.01879 9.73139 8.24223 10.074L9.06951 11.3388C9.18953 11.523 9.38713 11.6443 9.60593 11.6684C9.63363 11.6712 9.66041 11.673 9.68718 11.673C9.8783 11.673 10.0639 11.599 10.2024 11.4629L12.3297 9.38878C12.6214 9.1036 12.6279 8.63507 12.3444 8.34155C12.061 8.04802 11.5929 8.04066 11.3002 8.32676Z" fill="black" />
                <path d="M18.3496 1.52593H16.0783V0.740741C16.0783 0.331473 15.7478 0 15.3397 0C14.9316 0 14.601 0.331473 14.601 0.740741V1.52593H6.08083V0.740741C6.08083 0.331473 5.7503 0 5.3422 0C4.93409 0 4.60356 0.331473 4.60356 0.740741V1.52593H2.33225C1.0461 1.52593 0 2.57593 0 3.86482V15.9231C0 17.2129 1.0461 18.2629 2.33225 18.2629H18.3497C19.6358 18.2629 20.6819 17.2139 20.6819 15.9241V3.86479C20.6819 2.57591 19.6358 1.52593 18.3496 1.52593ZM19.2046 15.924C19.2046 16.3972 18.8205 16.7824 18.3496 16.7824L2.3322 16.7814C1.86132 16.7814 1.47723 16.3972 1.47723 15.924V3.86477C1.47723 3.39162 1.86132 3.00643 2.3322 3.00643H4.60351V3.787C4.60351 4.19627 4.93404 4.52774 5.34215 4.52774C5.75025 4.52774 6.08079 4.19627 6.08079 3.787V3.00736H14.601V3.78792C14.601 4.19719 14.9315 4.52866 15.3396 4.52866C15.7477 4.52866 16.0783 4.19719 16.0783 3.78792V3.00736H18.3496C18.8205 3.00736 19.2045 3.39254 19.2045 3.86477L19.2046 15.924Z" fill="black" />
              </svg>

              <a href="https://api.whatsapp.com/send/?phone=573207379535&text=Hola%21%20me%20gustar%C3%ADa%20saber%20m%C3%A1s%20sobre%20LOKL%20y%20agendar%20una%20cita😊" className="no-underline font-semibold text-lg font-epilogue text-nowrap" target='_blanck'>Agendar una cita</a>
            </div> */}

            <div className="flex items-center gap-2">
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.55988 3.06219C9.80324 2.47927 11.1967 2.47927 12.44 3.06219L18.2948 5.80706C19.5684 6.40412 19.5684 8.47092 18.2948 9.06797L12.4401 11.8128C11.1968 12.3957 9.80332 12.3957 8.55996 11.8128L2.70515 9.06797C1.43161 8.47088 1.43162 6.40408 2.70515 5.80702L8.55988 3.06219Z" stroke="#1C274C" strokeWidth="1.5" />
                <path d="M1.75 7.4375V12.25" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M16.625 10.0625V14.5472C16.625 15.4292 16.1844 16.255 15.4129 16.6824C14.128 17.3939 12.0715 18.375 10.5 18.375C8.9285 18.375 6.87199 17.3939 5.58716 16.6824C4.81556 16.255 4.375 15.4292 4.375 14.5472V10.0625" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
              </svg>

              <Link to="/blog" id='btnBlog' className="no-underline font-semibold text-lg font-epilogue text-nowrap">Aprende</Link>
            </div>

          </div>
        </div>

        <div className="navbar-right flex justify-end items-center relative top-0">

          {
            authed === false &&
            <>
              <div className="cursor-pointer flex gap-2 items-center">
                <div onClick={() => goToLogin()} className="rounded-full bg-[#ECECFD] flex gap-1 items-end px-4 py-[13px]">
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 8.75C12.433 8.75 14 7.183 14 5.25C14 3.317 12.433 1.75 10.5 1.75C8.567 1.75 7 3.317 7 5.25C7 7.183 8.567 8.75 10.5 8.75Z" stroke="#2724F3" strokeWidth="1.5" />
                    <path d="M10.5 18.375C13.8827 18.375 16.625 16.808 16.625 14.875C16.625 12.942 13.8827 11.375 10.5 11.375C7.11726 11.375 4.375 12.942 4.375 14.875C4.375 16.808 7.11726 18.375 10.5 18.375Z" stroke="#2724F3" strokeWidth="1.5" />
                  </svg>
                  <h2 className="no-underline text-lg font-semibold text-[#1A17F9] font-epilogue leading-none text-nowrap">Iniciar sesión</h2>
                </div>

                <div onClick={() => goToRegister()} className="cursor-pointer rounded-full bg-[#EBEBEB] flex gap-1 items-end px-4 py-[16px]">
                  <h2 className="no-underline text-lg font-semibold font-epilogue leading-[initial]">Regístrate</h2>
                </div>
              </div>

            </>
          }

          {
            authed === true &&
            <div onClick={() => navigate('/dashboard')} className="cursor-pointer flex gap-2 items-center">
              <div className="rounded-full bg-[#ECECFD] flex gap-1 items-end px-4 py-3">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 8.75C12.433 8.75 14 7.183 14 5.25C14 3.317 12.433 1.75 10.5 1.75C8.567 1.75 7 3.317 7 5.25C7 7.183 8.567 8.75 10.5 8.75Z" stroke="#2724F3" stroke-width="1.5" />
                  <path d="M10.5 18.375C13.8827 18.375 16.625 16.808 16.625 14.875C16.625 12.942 13.8827 11.375 10.5 11.375C7.11726 11.375 4.375 12.942 4.375 14.875C4.375 16.808 7.11726 18.375 10.5 18.375Z" stroke="#2724F3" stroke-width="1.5" />
                </svg>
                <h2 className="no-underline text-lg font-semibold text-[#1A17F9] font-epilogue leading-none text-nowrap">Mis inversiones</h2>
              </div>
            </div>
          }

        </div>

      </nav>

      {
        !routesToHideTopBarBanner.includes(location.pathname) &&
        <div className="hidden lg:flex justify-center items-center w-full py-3 mt-2 bg-gradient-to-r from-[#E0C3FC] to-[#8EC5FC] fixed top-14 z-40">

          <div className="font-lato text-lg mr-32">

            <span className='text-[#17008A] font-extrabold'>¡Disfruta de <span className='underline cursor-pointer' onClick={() => redirecToBenefits()}>beneficios exclusivos</span> siendo inversionista!  </span> <br /> y crece como socio invirtiendo en proyectos con LOKL

          </div>

          <div className="flex items-center">

            <div className="mr-5">

              <div className="text-sm">

                Aumento del Unit en

              </div>

              <div className="flex justify-between items-start space-x-3">

                <div className="text-center">

                  <div className="text-2xl font-bold">{countdown.days}</div>
                  <div className="text-xs">Días</div>

                </div>

                <div className="text-2xl font-bold">:</div>

                <div className="text-center">

                  <div className="text-2xl font-bold">{countdown.hours}</div>
                  <div className="text-xs">Hrs</div>

                </div>

                <div className="text-2xl font-bold">:</div>

                <div className="text-center">

                  <div className="text-2xl font-bold">{countdown.minutes}</div>
                  <div className="text-xs">Min</div>

                </div>

                <div className="text-2xl font-bold">:</div>

                <div className="text-center">

                  <div className="text-2xl font-bold">{countdown.seconds}</div>
                  <div className="text-xs">Seg</div>

                </div>

              </div>

            </div>

            <button id='btnTopbarBannerInvertir' className='btn-white' onClick={() => isAuthenticated(true)}>Invertir ahora</button>

          </div>

        </div>

      }

      <nav className={`navbar-mobile shadow-sm ${inDashboard ? 'bg-black' : 'bg-white'}`}>
        <div className="navbar-menu"
          onClick={() => setShow(!show)}
        >
          <img src={inDashboard ? menuWhite : menu} alt="lokl-menu" />
        </div>

        <div className="navbar-logo">
          <Link to="/">
            <img width={80} height={40} src={inDashboard ? logoWhite : logo} alt="lokl-logo" />
          </Link>
        </div>

        { authed === false ?
            <div className="navbar-avatar" onClick={() => isAuthenticated()}>
              <img src={avatar} alt="navbar-avatar" />
            </div>
            :
            <div className="navbar-avatar" onClick={() => closeSession()}>
              <img src={avatarAuthed} alt="navbar-avatar" />
            </div>
        }

        {
          inDashboard &&
          <div className="flex lg:hidden items-center fixed top-[60px] left-0 right-0 z-50 min-h-[120px] text-sm bg-black text-white py-4 px-5">

            <div className="">

              <div className="w-48 text-2xl font-bold font-lato capitalize">
                {name}
              </div>

              <div
                className="text-sm text-[#FFCBD7] underline"
                onClick={() => window.open('https://docs.google.com/forms/d/1xV5MwodhzA1MGZcWnRlyxxwFDuLjWQcQu2quvDeBQDc/viewform?edit_requested=true')}
              >
                Completa tu perfil personal
              </div>

            </div>

          </div>

        }

        {
          !routesToHideTopBarBanner.includes(location.pathname) &&
          <div className={`fixed ${inDashboard ? 'top-[180px]' : 'top-[60px]'} left-0 right-0 z-50 text-sm py-2.5 bg-gradient-to-r from-[#E0C3FC] to-[#8EC5FC] text-black`}>
            <motion.div
              className="flex whitespace-nowrap h-full space-x-5"
              animate={{
                x: [100, -1150],
              }}
              transition={{
                repeat: Infinity,
                duration: 20,
                ease: 'linear',
              }}
              onClick={() => redirecToBenefits()}
            >

              <div className="flex justify-center items-center font-extrabold font-lato text-lg mr-20">
                <span className='text-[#17008A] mr-1.5'>¡Disfruta de <span className='underline cursor-pointer' onClick={() => redirecToBenefits()}>beneficios exclusivos</span> siendo inversionista!  </span> y crece como socio invirtiendo en proyectos con LOKL, en {currentTime} aumentará el precio del Unit
              </div>

              <div className="flex justify-center items-center font-extrabold font-lato text-lg mr-20">
                <span className='text-[#17008A] mr-1.5'>¡Disfruta de <span className='underline cursor-pointer' onClick={() => redirecToBenefits()}>beneficios exclusivos</span> siendo inversionista!  </span> y crece como socio invirtiendo en proyectos con LOKL, en {currentTime} aumentará el precio del Unit
              </div>

            </motion.div>
          </div>

        }

      </nav>

      {/* <div 
        className="navbar-line" 
        style={{ width: barWith }} 
      ></div> */}

    </>
  );
}

export default Topbar;