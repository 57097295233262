import React, { useEffect, useRef, useState } from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import FirstIcon from '../../../assets/img/about-us/img-tree.png';
import SecondIcon from '../../../assets/img/about-us/img-financials.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CardCarrouselDifferent } from '../components/WeReDifferentFolder';
import { motion } from 'framer-motion';

export const WeReDifferent = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null); 

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect(); 
            }
        }, {
            threshold: 0.1, 
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
      <div className="relative w-full min-h-[100vh] overflow-hidden bg-[#f0f2f4]" ref={sectionRef}>


       
        <motion.div 
          className={`text-right flex px-3 justify-between mt-20 items-start ${isVisible ? 'fade-in' : 'fade-out'}`}
          initial={{ opacity: 0, y: -20 }} 
          animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }} 
          transition={{ duration: 0.5 }}
        >
          <div className="text-left md:ml-32  text-4xl md:text-5xl lg:text-8xl">
            <h2 className="font-light primaryFont">SOMOS</h2>
            <h1 className="font-bold italic primaryFont">DIFERENTES</h1>
          </div>
          <div className="text-right mr-10 md:mr-40 text-2xl md:text-3xl lg:text-4xl">
            <p className="secondaryFont leading-tight">
              <span className='secondaryFont font-semibold'>CREAMOS</span><br />
              <span className='secondaryFont font-thin'>TODO</span><br />
              PENSANDO<br />
              <span className='secondaryFont font-bold'>EN TI</span>
            </p>
          </div>
        </motion.div>
      
      
        <div className="relative w-full my-24 flex justify-center">


  <div className="w-full md:max-w-[1400px]">
    <Swiper 
      pagination={{ clickable: true }}
      modules={[Pagination]}
      className="mySwiper"
      breakpoints={{
        640: { slidesPerView: 1, spaceBetween: 40 }, 
        768: { slidesPerView: 2, spaceBetween: 30 },
        1024: { slidesPerView: 3, spaceBetween: 40 }
      }}
    >
      <SwiperSlide className="flex justify-center">
        <CardCarrouselDifferent
          src="https://lokl-assets.s3.amazonaws.com/about-us/business-colleagues-banner-conce.jpg"
          key={1}
          textButton="Ver más"
          text="Inversiones accesibles"
          link="/blog"
        />
      </SwiperSlide>
      <SwiperSlide className="flex justify-center">
        <CardCarrouselDifferent
          src="https://lokl-assets.s3.amazonaws.com/about-us/happy-parents-with-child-home.jpg"
          key={2}
          textButton="Ver más"
          text="Disfruta tus inversiones"
          link="/blog"
        />
      </SwiperSlide>
      <SwiperSlide className="flex justify-center">
        <CardCarrouselDifferent
          src="https://lokl-assets.s3.amazonaws.com/about-us/close-up-hands-holding-black-pho.jpg"
          key={3}
          textButton="Ver más"
          text="Inversión 100% digital"
          link="/blog"
        />
      </SwiperSlide>
    </Swiper>
  </div>
</div>
      
        
        <div className="relative grid items-center grid-cols-1 md:grid-cols-2 gap-8 mb-20 mt-8 px-10">
          <motion.div 
            className={`flex flex-col pl-0 md:pl-32 md:mb-24 mt-2 ${isVisible ? 'fade-in' : 'fade-out'}`}
            initial={{ opacity: 0, y: 20 }} 
            animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }} 
            transition={{ duration: 0.5, delay: 0.5 }} 
          >
            <img 
              src={SecondIcon} 
              alt="Icono Misión" 
              className="w-24 h-20  ml-4  md:ml-0 md:w-32 md:h-24 lg:w-44 lg:h-36 mb-2" 
              loading="lazy" 
            />
            <span className='ml-11'>
              <p className="primaryFont font-bold text-xl md:text-2xl lg:text-2xl">PROPÓSITO</p>
              <p className="secondaryFont  font-normal md:text-lg lg:text-2xl">
                Empoderamos a las nuevas generaciones <br />
                para que juntos construyamos<br />
                el futuro que queremos crear. <br />
              
              </p>
            </span>
          </motion.div>
      
          <motion.div 
            className={`flex flex-col md:pl-32 pl-0 ${isVisible ? 'fade-in' : 'fade-out'}`}
            initial={{ opacity: 0, y: 20 }} 
            animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }} 
            transition={{ duration: 0.5, delay: 0.7 }} 
          >
            <img 
              src={FirstIcon} 
              alt="Icono Visión" 
              className="w-24 h-20  ml-4  md:ml-0 md:w-32 md:h-24 lg:w-44 lg:h-36 mb-2" 
              loading="lazy" 
            />
            <span className='ml-11'>
              <p className="primaryFont font-bold text-xl  md:text-2xl lg:text-2xl">PROPUESTA DE VALOR</p>
              <p className="secondaryFont text-base font-normal md:text-lg lg:text-2xl">
                Ofrece a las nuevas generaciones la <br />
                oportunidad de ser dueños de proyectos  <br />
                que les apasionan. <br /><br />
                Con inversiones a partir de $1.000 USD <br />
                en el sector inmobiliario, todo de manera <br />
                100% digital.
              </p>
            </span>
          </motion.div>
        </div>



      </div>
      

    );
};
